<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6 col-lg-4">
        <div class="my-5">
          <Logo></Logo>

          <PageTitle :title="'Lost password'" class="text-center"></PageTitle>

          <form
            @submit.prevent="resetPassword"
            :class="isLoading ? 'is-busy' : ''"
            class="my-4"
          >
            <ErrorMessage v-if="error" :error="error"></ErrorMessage>

            <div v-if="isSuccess" class="alert alert-success" role="alert">
              Check your email to reset your password.
            </div>

            <div class="my-3 form-floating">
              <input
                v-model.trim="form.email"
                type="email"
                class="form-control"
                id="usermail"
                placeholder="user@domain.com"
              >
              <label for="usermail">Email address<sup class="text-danger">*</sup></label>
            </div>

            <SubmitButton
              :text="'Reset my password'"
              :textBusy="'Please wait...'"
              :isLoading="isLoading"
              :disabled="isLoading || isSubmitDisabled"
              class="btn btn-primary"
            ></SubmitButton>
          </form>

          <div class="my-5 pt-5 border-top text-center">
            <router-link
              to="/login"
              class="link-primary"
            >Back to login</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firebaseAuth } from '@/core/firebase';

export default {
  name: 'LostPassword',
  components: {
    Logo: () => import('@/components/Logo'),
    PageTitle: () => import('@/components/PageTitle'),
    ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    isSubmitDisabled() {
      return this.form.email === '';
    },
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      this.isSuccess = false;
      this.error = null;
      try {
        await firebaseAuth.sendPasswordResetEmail(this.form.email);
        this.form.email = '';
        this.isSuccess = true;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      error: null,
      form: {
        email: '',
      },
    };
  },
};
</script>
